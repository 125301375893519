import React from 'react';

import Layout from "../layouts/site/layout";

function four() {
  return (
    <div className="App">
       <Layout>
            <div className="px-4 pt-7 pb-6">
                <h2 className="text-red-1000 font-bold text-base">Page not found</h2>
            </div>
       </Layout>
    </div>
  );
}

export default four;
